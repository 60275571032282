import React from 'react';
import Link from 'next/link';
import { LightbulbIconOutline, SimpleCalendarIcon } from '../../modules/Icons';

export const containerStyle = 'sticky bottom-0 bg-a-dark-gray z-10 pb-6';
export const divider = 'border-t opacity-[20%] mx-4 pb-3';
export const ulStyle = 'px-[41px]';
export const liStyle = 'leading-[48px] text-[15px] text-white-60';
export const linkStyle =
  'flex items-center text-white-60 stroke-[#7F898D] hover:text-a-blue hover:stroke-a-blue';

export const BottomLink: React.FC = () => (
  <div className={containerStyle}>
    <div className={divider}></div>
    <ul className={ulStyle}>
      <li className={liStyle}>
        <Link
          href="https://actionvfx.featurebase.app/?b=65bfb4720ed972a2431dad35"
          className={linkStyle}>
          <LightbulbIconOutline className="stroke-inherit fill-inherit w-4 mr-4 inline" />
          Request a Product
        </Link>
      </li>
      <li className={liStyle}>
        <Link href="https://actionvfx.featurebase.app/roadmap" className={linkStyle}>
          <SimpleCalendarIcon className="stroke-inherit w-4 mr-4 inline" />
          Product Roadmap
        </Link>
      </li>
    </ul>
  </div>
);
