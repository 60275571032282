import { UseScrollPositionHook } from '../../types/hooks/hooksTypes';

const useScrollPosition = (selector: string): UseScrollPositionHook => {
  const storeScrollPosition = () => {
    const element = document.querySelector(selector);
    if (element instanceof HTMLElement) {
      sessionStorage.setItem('scrollPosition', element.scrollTop.toString());
    }
  };

  const loadScrollPosition = () => {
    const element = document.querySelector(selector);
    const position = sessionStorage.getItem('scrollPosition');
    if (element instanceof HTMLElement && position) {
      element.scrollTop = parseInt(position, 10);
      sessionStorage.removeItem('scrollPosition');
    }
  };

  return { storeScrollPosition, loadScrollPosition };
};

export default useScrollPosition;
