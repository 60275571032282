import { CategoryNavigationMobileProps } from '../../types/components/shared/CategoryMobileNavigationTypes';
import { DownChevronIcon } from '../../modules/Icons';

const CategoryNavigationMobile: React.FC<CategoryNavigationMobileProps> = ({
  category,
  showSidebar,
  setShowSidebar
}) => {
  return (
    <div className="block md:hidden bg-gray-900">
      <div
        onClick={() => setShowSidebar(!showSidebar)}
        className="flex items-center justify-center uppercase p-4 font-bold eurostile cursor-pointer">
        {category?.name || 'All Collections'} &nbsp;{' '}
        <DownChevronIcon
          className={`${showSidebar ? 'rotate-180 stroke-white' : 'stroke-white'}`}
        />
      </div>
    </div>
  );
};

export default CategoryNavigationMobile;
