import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../types/state/storeTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import NoSSR from 'react-no-ssr';
import {
  AllCollectionsIcon,
  NewReleasesIcon,
  EssentialsCategoryIcon,
  FreeGiftIcon
} from '../../modules/Icons';
import { SidebarProps } from '../../types/pages/products/CollectionsTypes';
import CategoryNavigationMobile from '../products/CategoryNavigationMobile';
import useScrollPosition from '../helpers/UseScrollPosition';
import { TopLevelLink } from './TopLevelLink';
import { BottomLink } from './BottomLink';
import { CategoryItem } from './CategoryItem';
import { allProductsLink, categoryBaseUrl } from '../../helpers/sidebarHelpers';
import { BlackFridayButton } from '../shared/BlackFridayButton';

const SidebarComponent: React.FC<SidebarProps> = ({
  categories,
  params,
  category,
  shallowLinks = false,
  subscription
}) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const router = useRouter();
  const { storeScrollPosition, loadScrollPosition } = useScrollPosition('.scrollable-div');
  const [ selectedPath, setSelectedPath ] = useState('');
  // have to call this b/c shallow linking prevents auto-scrolling to top
  const scrollToTop = () => window.scrollTo(0, 0);
  useEffect(() => {
    loadScrollPosition();
  }, [categories, category]);

  const handleLinkClick = () => {
    storeScrollPosition();
    scrollToTop();
  };

  return (
    <>
      <CategoryNavigationMobile
        category={category}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />

      <div
        className={`
        ${showSidebar ? 'block' : 'hidden'} md:block 
        sticky top-[64px] left-0
        h-[calc(100vh-64px)] 
        bg-a-dark-gray z-30
      `}>
        <nav className="md:w-[292px] bg-a-dark-gray text-white-60 font-normal h-full flex flex-col">
          <div className="z-10 mt-6 bg-a-dark-gray">
            <ul className="px-4">
              <TopLevelLink
                href={allProductsLink(params.library_id)}
                icon={
                  <AllCollectionsIcon className="inline w-4 mr-4 stroke-inherit fill-inherit" />
                }
                text="All Collections"
                selected={router.asPath === allProductsLink(params.library_id) || router.asPath.includes(`${allProductsLink(params.library_id)}?`)}
              />
              {/* {params.library_id != 3 && <TopLevelLink
                href={`/${params.library_id === 1 || !params.library_id ? 'products' : '3d'}/new`}
                icon={<NewReleasesIcon className="inline w-4 mr-4 stroke-inherit" />}
                text="New Releases"
                selected={router.asPath.includes('new')}
              />} */}
              <TopLevelLink
                href={`/${params.library_id === 1 || !params.library_id ? 'products' : '3d'}/essentials-catalog`}
                icon={<EssentialsCategoryIcon className="inline w-4 mr-4 stroke-inherit" />}
                text="Essentials Catalog"
                selected={router.asPath.includes('essentials-catalog')}
              />
              <TopLevelLink
                href={`${categoryBaseUrl(params.library_id)}/${
                  params.library_id === 3 ? 'free-assets' : 'free-vfx'
                }/category`}
                icon={<FreeGiftIcon className="inline w-4 mr-4 stroke-inherit" />}
                text="Free Assets"
                selected={router.asPath.includes('free')}
              />
              {!subscription && <BlackFridayButton type="collections" />}
              {subscription && !subscription.active && <BlackFridayButton type="collections" />}
            </ul>
          </div>
          <div className="border-t opacity-[20%] mx-4 mt-3 pb-3"></div>
          <div className="scrollable-div flex-grow scrollbar-custom pb-6 mt-[-8px]">
            <div className="text-white-60 text-[15px] mt-4 uppercase px-[41px]">Categories</div>
            <ul className="px-[41px]">
              {categories.map(
                (c) =>
                  !c.name.includes('Free') && (
                    <CategoryItem
                      key={c.id}
                      category={c}
                      selectedCategory={category}
                      baseUrl={categoryBaseUrl(params.library_id)}
                      shallowLinks={shallowLinks}
                      onLinkClick={handleLinkClick}
                      params={params}
                    />
                  )
              )}
            </ul>
          </div>
          <BottomLink />
        </nav>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState): SidebarProps => ({
  subscription: state.auth.subscription as SubscriptionDetail,
});

const ConnectedTopNav = connect(mapStateToProps)(SidebarComponent);

export default (props) => (
  <NoSSR>
    <ConnectedTopNav {...props} />
  </NoSSR>
);

