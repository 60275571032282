import React from 'react';
import Link from 'next/link';
import { getSubcategoryPath } from '../../helpers/sidebarHelpers';
import { SubCategoryListProps } from '../../types/pages/products/CollectionsTypes';

export const defaultLinkStyle =
  'flex items-center ml-1 pl-7 border-l hover:text-white hover:stroke-white hover:font-500 block hover:border-a-blue';
export const selectedLinkStyle = 'text-white stroke-white font-medium border-a-blue';
export const unselectedLinkStyle = 'text-white-60 stroke-white-60 border-gray-700';
export const totalElementsStyle = 'ml-auto text-[12px] text-gray-600';

export const SubCategoryList: React.FC<SubCategoryListProps> = ({
  subcategories,
  selectedCategory,
  category,
  shallowLinks,
  onLinkClick
}) => {
  if (!subcategories || subcategories.length === 0) {
    return null;
  }
  return (
    <ul>
      {subcategories
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((sc) => (
          <li key={sc.id}>
            <Link
              className={`${defaultLinkStyle} ${
                selectedCategory?.id === sc.id ? selectedLinkStyle : unselectedLinkStyle
              }`}
              href={getSubcategoryPath(category, sc)}
              shallow={shallowLinks}>
              <span className="w-full flex" onClick={onLinkClick}>
                {sc.name}
                <span className={totalElementsStyle}>{sc.total_elements}</span>
              </span>
            </Link>
          </li>
        ))}
    </ul>
  );
};
