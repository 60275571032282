import React from 'react';
import Link from 'next/link';
import { SubCategoryList } from './SubCategoryList';
import { FolderIcon } from '../../modules/Icons';
import { CategoryAssets } from '../../staticData/Categories';
import { categoryBaseUrl } from '../../helpers/sidebarHelpers';
import { CategoryItemProps } from '../../types/pages/products/CollectionsTypes';

export const selectedStyle = 'text-a-blue stroke-[#00A1E0] fill-[#00A1E0]';
export const unselectedStyle = 'text-white-60 stroke-white-60 fill-white-60';
export const defaultStyle =
  'flex items-center hover:text-a-blue hover:stroke-a-blue hover:fill-a-blue`';

export const CategoryItem: React.FC<CategoryItemProps> = ({
  category,
  selectedCategory,
  baseUrl,
  shallowLinks,
  onLinkClick,
  params
}) => {
  const isSelected =
    selectedCategory?.id === category.id || selectedCategory?.parent?.id === category.id;
  const linkStyles = isSelected ? selectedStyle : unselectedStyle;

  const getCategoryIcon = (category) => {
    const staticCategory = CategoryAssets.find(
      (ca) => ca.slug === category.slug && ca.href.includes(`${categoryBaseUrl(params.library_id)}`)
    );
    if (staticCategory) {
      return <div key={staticCategory.slug}>{staticCategory.icon}</div>;
    }
    return <FolderIcon className={`stroke-inherit w-4 mr-4 inline`} />;
  };

  return (
    <li className="leading-[48px] text-[15px] text-white-60">
      <Link
        className={`${defaultStyle} ${linkStyles}`}
        href={{ pathname: `${baseUrl}/${category.slug}/category` }}
        shallow={shallowLinks}>
        <span className="w-full flex" onClick={onLinkClick}>
          {getCategoryIcon(category)}
          {category.name}
          {category.total_elements > 0 && (
            <span className="ml-auto text-[12px] text-gray-600">{category.total_elements}</span>
          )}
        </span>
      </Link>
      {isSelected && (
        <SubCategoryList
          subcategories={category.subcategories}
          selectedCategory={selectedCategory}
          category={category}
          shallowLinks={shallowLinks}
          onLinkClick={onLinkClick}
        />
      )}
    </li>
  );
};
