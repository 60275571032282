import React from 'react';

const NoSearchResults: React.FC<any> = (props) => {
  const { term } = props;

  return (
    <div className="p-3">
      <p className="text-gray-600">
        We couldn't find any products matching
        <strong className="text-white"> {term}</strong>
      </p>
      <ul className="p-4 text-gray-600 list-disc">
        <li>Make sure all words are spelled correctly.</li>
        <li>Broaden your search by using fewer or more general words.</li>
        <li>Look through our Categories to find a similar product.</li>
      </ul>

      <p className="text-[18px] text-white py-4 font-bold">Popular Collections</p>
    </div>
  );
};

export default NoSearchResults;
