import {
  AllCollectionsIcon,
  AnimalsIcon,
  // AtmosphereIcon,
  BloodGoreIcon,
  DebrisIcon,
  ElectricityIcon,
  EnergyFXIcon,
  ExplosionsIcon,
  FireIcon,
  FreeGiftIcon,
  GlassIcon,
  GunFXIcon,
  LightingIcon,
  FolderIcon,
  IndustrialIcon,
  OpticsIcon,
  ParticlesIcon,
  PeopleIcon,
  PlantsIcon,
  SlimeIcon,
  SmokeIcon,
  SoundIcon,
  SpaceIcon,
  TexturesIcon,
  NewVarietyPackIcon,
  VehicleIcon,
  WaterIcon,
  WeatherIcon,
  WeaponIcon,
  AfterEffects,
  Nuke,
  Fusion,
  Resolve,
  Mocha,
  UnrealEngine,
  HitFilm,
  LandscapesIcon
} from '../modules/Icons';

export const CategoryAssets = [
  {
    text: 'Animals',
    href: '/collections/animals/category',
    base: '/products',
    slug: 'animals',
    icon: <AnimalsIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Atmosphere',
    href: '/collections/atmosphere/category',
    base: '/products',
    slug: 'atmosphere',
    icon: <FolderIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Blood & Gore',
    href: '/collections/blood-gore/category',
    base: '/products',
    slug: 'blood-gore',
    icon: <BloodGoreIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Debris & Impact',
    href: '/collections/debris-impact/category',
    base: '/products',
    slug: 'debris-impact',
    icon: <DebrisIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Debris & Impact',
    href: '/3d/debris-and-impact/category',
    base: '/3d',
    slug: 'debris-and-impact',
    icon: <DebrisIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Electricity',
    href: '/collections/electricity/category',
    base: '/products',
    slug: 'electricity',
    icon: <ElectricityIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Electricity',
    href: '/3d/electricity/category',
    base: '/3d',
    slug: 'electricity',
    icon: <ElectricityIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Energy FX',
    href: '/collections/energy-fx/category',
    base: '/products',
    slug: 'energy-fx',
    icon: <EnergyFXIcon className="w-4 mr-4 inline" />
  },
  {
    text: 'Explosions',
    href: '/collections/explosions/category',
    base: '/products',
    slug: 'explosions',
    icon: <ExplosionsIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Fire',
    href: '/collections/fire/category',
    base: '/products',
    slug: 'fire',
    icon: <FireIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Free VFX',
    href: '/collections/free-vfx/category',
    base: '/products',
    slug: 'free-vfx',
    icon: <FreeGiftIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  // NOT CORRECT HREF
  {
    text: 'Free with AFVX+',
    href: '/collections/free-for-subscribers',
    base: '/products',
    slug: 'free-for-subscribers',
    icon: <FolderIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Glass',
    href: '/collections/glass/category',
    base: '/products',
    slug: 'glass',
    icon: <GlassIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Gun FX',
    href: '/collections/gun-fx/category',
    base: '/products',
    slug: 'gun-fx',
    icon: <GunFXIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'HDRIs & Lighting',
    href: '/3d/hdris-and-lighting/category',
    base: '/3d',
    slug: 'hdris-lighting',
    icon: <LightingIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Industrial',
    href: '/3d/industrial/category',
    base: '/3d',
    slug: 'industrial',
    icon: <IndustrialIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Landscapes',
    href: '/3d/landscapes/category',
    base: '/3d',
    slug: 'landscapes',
    icon: <LandscapesIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Miscellaneous',
    href: '/collections/miscellaneous/category',
    base: '/products',
    slug: 'miscellaneous',
    icon: <FolderIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Optics',
    href: '/collections/optics/category',
    base: '/products',
    slug: 'optics',
    icon: <OpticsIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Sparks',
    href: '/collections/sparks/category',
    base: '/products',
    slug: 'sparks',
    icon: <ParticlesIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'People & Crowds',
    href: '/collections/people-crowds/category',
    base: '/products',
    slug: 'people-crowds',
    icon: <PeopleIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Plants & Trees',
    href: '/collections/plants-trees/category',
    base: '/products',
    slug: 'plants-trees',
    icon: <PlantsIcon className="fill-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Pyro',
    href: '/3d/pyro/category',
    base: '/3d',
    slug: 'pyro',
    icon: <FireIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Slime',
    href: '/collections/slime/category',
    base: '/products',
    slug: 'slime',
    icon: <SlimeIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Smoke & Fog',
    href: '/collections/smoke/category',
    base: '/products',
    slug: 'smoke',
    icon: <SmokeIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Smoke & Fog',
    href: '/3d/smoke-and-fog/category',
    base: '/3d',
    slug: 'smoke-and-fog',
    icon: <SmokeIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Sound FX',
    href: '/collections/sound-fx/category',
    base: '/products',
    slug: 'sound-fx',
    icon: <SoundIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Space',
    href: '/collections/space/category',
    base: '/products',
    slug: 'space',
    icon: <SpaceIcon className="fill-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Textures',
    href: '/collections/textures/category',
    base: '/products',
    slug: 'textures',
    icon: <TexturesIcon className="fill-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Variety Packs',
    href: '/collections/variety-packs/category',
    base: '/products',
    slug: 'variety-packs',
    icon: <NewVarietyPackIcon className="fill-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Vehicles',
    href: '/3d/vehicles/category',
    base: '/3d',
    slug: 'vehicles',
    icon: <VehicleIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Water',
    href: '/collections/water/category',
    base: '/products',
    slug: 'water',
    icon: <WaterIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Weapons',
    href: '/3d/weapons/category',
    base: '/3d',
    slug: 'weapons',
    icon: <WeaponIcon className="stroke-inherit w-4 mr-4 inline" />
  },
  {
    text: 'Weather',
    href: '/collections/weather/category',
    base: '/products',
    slug: 'weather',
    icon: <WeatherIcon className="stroke-inherit w-4 mr-4 inline" />
  }
];

export const BlogSoftwareCategories = [
  {
    text: 'After Effects',
    icon: <AfterEffects className="mr-4 inline w-[24px] h-[24px]" />
  },
  {
    text: 'Nuke',
    icon: <Nuke className="mr-4 inline w-[24px] h-[24px]" />
  },
  {
    text: 'Fusion',
    icon: <Fusion className="mr-4 inline w-[24px] h-[24px]" />
  },
  {
    text: 'Resolve',
    icon: <Resolve className="mr-4 inline w-[24px] h-[24px]" />
  },
  {
    text: 'Mocha',
    icon: <Mocha className="mr-4 inline w-[24px] h-[24px]" />
  },
  {
    text: 'Unreal Engine',
    icon: <UnrealEngine className="mr-4 inline w-[24px] h-[24px]" />
  },
  {
    text: 'HitFilm',
    icon: <HitFilm className="mr-4 inline w-[24px] h-[24px]" />
  }
];
